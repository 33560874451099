import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TransProps, useTranslation } from 'react-i18next';

import { FrontendApiError, ManagementUser } from '@chic/models';
import { getUserManagementData } from '@chic/api';
import { Container, InnerContainer, LoaderWrapper, PageTitleWrapper, StyledPageTitle } from './userRoleVerification.styled';
import { useTitle } from '@chic/hooks';
import { PermissionScopeName, RoutingPath } from '@chic/enums';
import { 
  Color, 
  ComponentColorTheme, 
  DetailsInfoGroup,  
  FullscreenAlertTheme,  
  Loader,  
  PageTitle, 
  PageTitleSize,
  UseNotifications, 
  UseRedirect, 
  UseState, 
  useNotifications, 
  useRedirect, 
} from '@chic-loyalty/ui';
import { RoleVerificationBox } from '@chic/components';

export const UserRoleVerificationView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { userId }: Readonly<Partial<Record<string, string>>> = useParams<Record<string, string>>();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(true);
  const [userManagementData, setUserManagementData]: UseState<ManagementUser | null> = useState<ManagementUser | null>(null);
  useTitle(t('chic.crmApp.userVerification.title'));

  const handleCloseAlertAction: () => void = (): void => {
    hideFullscreenAlert();
    redirect(RoutingPath.UserRoleVerificationList);
  };

  useEffect(
    (): void => {
      if (userId?.match(/^\d+$/)) {
        getUserManagementData(parseInt(userId, 10))
          .then((data: ManagementUser): void => {
            setUserManagementData(data);
            setIsLoading(false);

            if (!data.availableScopes.includes(PermissionScopeName.UserVerificationVerify)) {
              showFullscreenAlert({
                description: t('chic.crmApp.userVerification.noVerificationPermission.description'),
                fullscreenAlertTheme: FullscreenAlertTheme.Error,
                acceptButtonSettings: { 
                  label: t('chic.crmApp.userVerification.noVerificationPermission.goBack'), 
                  action: handleCloseAlertAction, 
                },
              });
            }
          })
          .catch((error: FrontendApiError): void => {
            showFullscreenAlert({
              description: error.message ?? '',
              fullscreenAlertTheme: FullscreenAlertTheme.Error,
              acceptButtonSettings: { 
                label: t('chic.crmApp.userVerification.noVerificationPermission.goBack'), 
                action: handleCloseAlertAction, 
              },
            });
            setIsLoading(false);
          },
          );
      } else {
        redirect(RoutingPath.UserRoleVerificationList);
      }
    },
    [userId],
  );
  
  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle 
          size={PageTitleSize.Big} 
          label={t('chic.crmApp.userVerification.pageTitle')} 
          onClick={(): void => redirect(RoutingPath.UserRoleVerificationList)} 
        />
      </PageTitleWrapper>
      <InnerContainer>
        {isLoading ? (
          <LoaderWrapper>
            <Loader colorTheme={ComponentColorTheme.IC} size={36} color={Color.ICYellow100} />
          </LoaderWrapper>
        ) : !!userManagementData && (
          <DetailsInfoGroup 
            items={[
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.crmId.label'),
                value: String(userManagementData.crmData.id),
                valueColor: Color.ICBlue,
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.nip.label'),
                value: String(userManagementData.crmData.companyInfo.nip || '-'),
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.name.label'),
                value: userManagementData.crmData.name || '-',
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.email.label'),
                value: userManagementData.crmData.email || '-',
                wrap: true,
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.roles.label'),
                value: userManagementData.crmData.roles.join(', ') || '-',
                wrap: true,
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.username.label'),
                value: userManagementData.crmData.username || '-',
              },
              {
                label: t('chic.crmApp.userVerification.detailsInfoGroup.company.label'),
                value: userManagementData.crmData.companyInfo.name || '-',
              },
            ]}
            colorTheme={ComponentColorTheme.IC}
          />
        )}
        <StyledPageTitle label={t('chic.crmApp.userVerification.verification.pageTitle')} />
        {!!userId && <RoleVerificationBox userName={userManagementData?.crmData.name ?? ''} userId={Number(userId)} />}
      </InnerContainer>
    </Container>
  );
};
