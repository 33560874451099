import { AxiosResponse } from 'axios';
import { stringify } from 'query-string';

import { ApiReject, ApiResolve, ConsumerLoginData, ConsumerLoginWithoutSms, UserWithoutSmsVerificationData } from '@chic/models';

import { api } from '../api.service';

export const verifyUserWithoutSms = (data: ConsumerLoginWithoutSms): Promise<ConsumerLoginData> => new Promise(
  (resolve: ApiResolve<ConsumerLoginData>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/backoffice/user?${stringify(data, { skipEmptyString: true })}`)
      .then((response: AxiosResponse<ConsumerLoginData>) => resolve(response?.data))
      .catch(reject)
  ),
);

export const registerUserWithoutSmsVerification = (userData: UserWithoutSmsVerificationData): Promise<ConsumerLoginData> => new Promise(
  (resolve: ApiResolve<ConsumerLoginData>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/backoffice/register/user', userData)
      .then((response: AxiosResponse<ConsumerLoginData>) => resolve(response?.data))
      .catch(reject)
  ),
);
