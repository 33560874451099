import { Breakpoint, CalcSize, Color, DetailsInfoGroup, FontWeight, Grid, Markdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { PlansStagesTable } from './components';

export const StyledPlansStagesTable: StyledComponent<typeof PlansStagesTable> = styled(PlansStagesTable)`
  margin-top: 40px;
`;

export const TableDescription: StyledComponent<'p'> = styled.p`
  margin: 5px 0 30px;
  font-size: 8px;
`;

export const StyledDetailsInfoGroup: StyledComponent<typeof DetailsInfoGroup> = styled(DetailsInfoGroup)`
  border-top: 1px solid ${Color.ICWhite100};
`;

export const AttachmentsContainer: StyledComponent<'div'> = styled.div``;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - ${CalcSize.FooterHeight} - ${CalcSize.HealthWarningHeight});
  color: ${Color.ICWhite100};
`;

export const MainContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  padding: 0 24px 22px;
`;

export const SectionWithHeadline: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0 40px;
`;

export const SectionTextItalic: StyledComponent<typeof Markdown> = styled(Markdown)`
  color: ${Color.ICWhite100};
  font-size: 14px;
  line-height: 22px;
  font-style: italic;

  strong {
    font-weight: ${FontWeight.SemiBold};
  }
`;

export const SectionText: StyledComponent<typeof Markdown> = styled(Markdown)`
  color: ${Color.ICWhite100};
  font-size: 14px;
  line-height: 22px;
  font-weight: ${FontWeight.Thin};

  strong {
    font-weight: ${FontWeight.SemiBold};
  }

  a {
    color: ${Color.ICWhite100};
  }

  p + p {
    margin-top: 20px;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    
    li {
      p {
        display: inline;
      }
    }

    li + li {
      margin-top: 20px;
    }
  }
`;

export const LoadingContainer: StyledComponent<'div'> = styled.div`
  min-height: calc(100vh - 171px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const OrderedList: StyledComponent<'ol'> = styled.ol`
  counter-reset: list-item;
  list-style-type: none;
`;

export const ListItem: StyledComponent<'li'> = styled.li`
  margin-bottom: 15px;
  counter-increment: list-item;
  line-height: 22px;
  font-size: 14px;
  font-weight: ${FontWeight.Thin};
  word-break: break-word;

  &::before {
    content: counters(list-item, ".") ". ";
    display: inline-block;
    margin-right: 5px;
  }
`;

export const AlphabeticalList: StyledComponent<'ol'> = styled.ol`
  list-style-type: lower-alpha;
  padding-left: 26px;
  margin-top: 15px;

  ${ListItem} {
    ::before {
      content: none;
    }
  }
`;

export const ParagraphsSection: StyledComponent<'ol'> = styled.ol`
  & > ${ListItem} {
    &::before {
      content: none;
    }
  }
`;

export const GrayBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  background-color: ${Color.ICBlack400};
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack300};
    padding: 20px;
  }
`;

export const ParagraphHeader: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 50px 0 20px;
  font-weight: ${FontWeight.SemiBold};
  position: relative;
  padding-left: 24px;

  &::before {
    content: '§'counters(list-item, ".") ". ";
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);
    height: 1px;
    width: 16px;
    background-color: ${Color.ICWhite100};
  }
`;
