import { UserRoleVerificationListActions } from './userRoleVerificationList.enum';
import { UserRoleVerificationListAction, UserRoleVerificationListState } from './userRoleVerificationList.types';

export const userRoleVerificationListReducer: (
  state: UserRoleVerificationListState, action: UserRoleVerificationListAction,
) => UserRoleVerificationListState = (
  state: UserRoleVerificationListState, action: UserRoleVerificationListAction,
): UserRoleVerificationListState  => {
  switch (action.type) {
    case UserRoleVerificationListActions.SetSortKey:
      return { ...state, sortKey: action.payload };
    case UserRoleVerificationListActions.SetSortOrder:
      return { ...state, sortOrder: action.payload };
    case UserRoleVerificationListActions.SetFilterCrmIdValue:
      return { ...state, filterCrmIdValue: action.payload };
    case UserRoleVerificationListActions.SetFilterNameValue:
      return { ...state, filterNameValue: action.payload };
    case UserRoleVerificationListActions.SetFilterEmailValue:
      return { ...state, filterEmailValue: action.payload };
  }
};
